<template>
  <div class="our-service">
    <Banner :bannerInfo="bannerInfo" />
    <div class="info">
      <p>
        Our Sunday Morning Service starts at 10.45am. Church is open from
        10.15am so if you arrive early you can have a cuppa before the service
        starts.
      </p>
      <p>
        Car parking is available opposite the church in
        <strong>Solutions car park</strong>
        (Sundays only).
      </p>
      <p>
        During the service we worship God through singing, prayer and listening
        to a talk which is generally around 20- 25 minutes or so.
      </p>
      <p>
        As we gather we acknowledge that God is present with us, we try to lean
        in to Him to listen to His Holy Spirit so that we can be encouraged,
        guided and challenged. The atmosphere in church is quite relaxed, our
        songs are contemporary and everyone is welcome to just be themselves.
      </p>
      <p>
        After the service we usually have a drink and a biscuit and catch up
        with each other’s news.
      </p>
      <p>
        A <strong>loop system</strong> fitted in the Church just switch your
        hearing aid to ‘T’.
      </p>
      <p>
        The inside of our building is modern and light with carpet and chairs
        which allows us to be flexible when we gather.
      </p>
      <p>
        On the first Sunday of the month we have a cafe style church where we
        sit round tables and have drinks and breakfast pastries. This way of
        worshipping together is a little more interactive and may involve a
        table activity. It’s not just the children who enjoy this style but
        everyone!
      </p>
      <p>
        On the second Sunday of the month we also share Communion together –
        eating bread and drinking wine, focusing our thoughts on all that Jesus
        has done for us. This time is very special. At the Communion table we
        expect to meet Jesus afresh and invite Him to continue to work in our
        lives.
      </p>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue";
export default {
  name: "OurServiceView",
  components: { Banner },
  data() {
    return {
      bannerInfo: {
        image: require("@/assets/ServiceImage.jpg"),
        title1: "JOIN US",
        title2: "SUNDAYS at 10:45AM",
      },
    };
  },
};
</script>

<style scoped>
.our-service {
  padding-top: 40px;
}

.info {
  width: 65vw;
  margin: 80px auto;
  padding: 0 80px;
}

.info p {
  margin: 20px 0;
  line-height: 27.5px;
}

@media screen and (max-width: 500px) {
  .info {
    padding: 0 20px;
  }
}
</style>
