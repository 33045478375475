<template>
  <div class="home">
    <div class="hong-kong-banner">
      <img
        src="@/assets/WelcomeBanner.jpeg"
        alt="We welcome people from Hong Kong and from all over the world!"
      />
    </div>
    <div class="tag-line">
      <h1>A SMALL CHURCH</h1>
      <h1>A BIG HEART FOR GOD</h1>
    </div>
    <!-- EVENT BANNER -->
    <EventBanner style="display: normal" event="christmas" />
    <!-- ------ -->
    <NavCardGrid />
    <div class="find-out-more">
      <h1>Welcome to the Bridge Baptist Church</h1>
      <p>
        We meet on Sundays at 10.45 for lively, spirit led worship, prayer and
        teaching.
      </p>
      <p>
        <br />Car parking is available opposite the church in Solutions car park
        (Sundays only).
      </p>
      <div class="find-out-more-button" @click="changePage">
        <h2>FIND OUT MORE</h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavCardGrid from "@/components/HomePageComponents/NavCardGrid";
import EventBanner from "@/components/HomePageComponents/EventBanner";
export default {
  name: "HomeView",
  components: {
    NavCardGrid,
    EventBanner,
  },
  methods: {
    changePage() {
      this.$router.push({ path: "/whoarewe" });
    },
  },
};
</script>

<style scoped>
.home {
  padding: 40px 0 0;
}

.hong-kong-banner {
  width: 100vw;
  background: #ec0106;
  padding: 10px 0 5px;
  margin-bottom: 50px;
}

.hong-kong-banner img {
  width: 400px;
}

@media screen and (max-width: 400px) {
  .hong-kong-banner img {
    width: 300px;
  }
}

.tag-line {
  width: 80vw;
  margin: 0 auto;
}

.tag-line h1 {
  width: 95%;
  margin: 0 auto;
  padding-bottom: 20px;
  font-size: 50px;
}

.find-out-more {
  width: 80vw;
  margin: auto;
  padding: 20px 0;
}

.find-out-more h1 {
  width: 95%;
  margin: 10px auto 60px;
  font-size: 40px;
}

.find-out-more p {
  font-size: 25px;
}

.find-out-more-button {
  width: 161px;
  height: 64px;
  margin: 5vw auto 20px;
  padding: 0 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4vw solid black;
  border-width: 2.5px;
  border-radius: 40px;
  cursor: pointer;
  font-size: 14.3px;
  transition: color 0.3s ease-in-out, background 0.3s ease-in-out;
}

.find-out-more-button:hover {
  background: black;
  color: white;
}
</style>
